<template>
  <div class="wrapper">
    <div class="animated fadeIn">
      <BRow>

        <BCol cols="12" md="12">

          <BCard header-tag="header" footer-tag="footer">

            <div slot="header">
              <BIconPersonLinesFill/> <strong> 사용자 설정 </strong>


              <div class="card-header-actions">
                <small class="text-muted">사용자 정보를 설정합니다.</small>
              </div>
            </div>

            <BRow class="mb-2">
              <BCol></BCol>
              <BCol sm="5">
                <BInputGroup prepend="사용자 검색"  size="sm">
                  <BFormSelect v-model="searchField"
                               size="sm"
                               :options="searchOpts"/>
                  <BInputGroupAppend>
                  <BFormInput v-model="searchWord" size="sm" v-on:keyup.enter="getUserList"/>
                  </BInputGroupAppend>
                </BInputGroup>
              </BCol>
            </BRow>

            <!-- User Table -->
            <BTable small sticky-header="500px" selectable hover responsive bordered
                    class="text-nowrap small"
                    thead-class="text-center"
                    ref="userTable"
                    id="userTable"
                    select-mode="single"
                    selectedVariant="success"
                    :per-page="userPerPage"
                    :current-page="userCurrPage"
                    :items="userItems"
                    :fields="userFields"
                    @row-selected="userRowSelected">
            </BTable>

            <BRow>
              <BCol>
                <BPagination
                  size="sm"
                  v-model="userCurrPage"
                  :total-rows="userRows"
                  :per-page="userPerPage"
                  aria-controls="userTable"
                ></BPagination>
              </BCol>
              <BCol class="text-right">
                <BButton
                  v-show="isUserSelected"
                  class="mr-1"
                  @click="isUserFormShow=true"
                  variant="info"><strong>{{user.user_id}}</strong> 사용자 정보 수정
                </BButton>

                <BButton
                  v-if="isAdmin"
                  @click="addUser"
                  variant="primary"><strong>사용자 추가</strong>
                </BButton>
              </BCol>
            </BRow>


            <BCard header-tag="header" v-show="isUserFormShow">
              <div slot="header">
                <BIcon icon="collection-fill"/> <strong> 사용자 정보 </strong>
                <div class="card-header-actions">
                  <small class="text-muted">사용자정보를 관리합니다</small>
                </div>
              </div>
              <BForm @submit="userSubmit">
                <BRow>
                  <BCol sm="1"></BCol>
                  <BCol>
                    <BFormGroup class="mb-1" id="form-01" label="ID" label-cols="3" label-for="input-id" label-align="right">
                      <BFormInput id="input-id"
                                  v-model="user.user_id"
                                  type="text"
                                  placeholder="User ID를 입력하세요"
                                  :disabled="actionMode==='put'"
                                  required/>
                    </BFormGroup>
                    <BFormGroup class="mb-1" id="form-010" label="이름" label-cols="3" label-for="display-name" label-align="right">
                      <BFormInput id="display-name"
                                  v-model="user.name"
                                  type="text"
                                  placeholder="이름을 입력 하세요"
                                  required/>
                    </BFormGroup>
                    <BFormGroup v-if="actionMode==='put' && !isAdmin " class="mb-1" id="form-02" label="현재 비밀번호" label-cols="3" label-for="input-current-pwd" label-align="right">
                      <BFormInput id="input-current-pwd"
                                  v-model="originPassword"
                                  :state="validationOriginPassword"
                                  type="password"
                                  placeholder="Password"
                                  :disabled="actionMode==='post'"
                                  :required="actionMode==='put'"/>

                    </BFormGroup>
                    <BFormGroup class="mb-1" id="form-03" label="새로운 비밀번호" label-cols="3" label-for="input-new-pwd" label-align="right">
                      <BFormInput id="input-new-pwd"
                                  v-model="newPassword"
                                  :state="validationPassword"
                                  type="password"
                                  placeholder="새로운 Password 6~50자 입력"
                                  :required="actionMode==='post'"/>
                    </BFormGroup>
                    <BFormGroup class="mb-1" id="form-04" label="새로운 비밀번호 확인" label-cols="3" label-for="input-re-pwd" label-align="right">
                      <BFormInput id="input-re-pwd"
                                  v-model="rePassword"
                                  :state="validationRePassword"
                                  type="password"
                                  :required="actionMode==='post'"/>
                    </BFormGroup>

                    <BFormGroup class="mb-1" id="form-06" label="소속 팀" label-cols="3" label-for="input-user-dept" label-align="right">
                      <BFormInput id="input-user-dept"
                                  v-model="user.dept"
                                  type="text"
                                  placeholder="소속팀 명 입력"/>
                    </BFormGroup>
                    <BFormGroup class="mb-1" id="form-07" label="전화" label-cols="3" label-for="input-phone-no" label-align="right">
                      <BFormInput id="input-phone-no"
                                  type="text"
                                  v-model="user.phone_no"
                                  placeholder="전화 번호 입력"/>
                    </BFormGroup>
                    <BFormGroup class="mb-1" id="form-17" label="휴대전화" label-cols="3" label-for="input-mobile-no" label-align="right">
                      <BFormInput id="input-mobile-no"
                                  type="text"
                                  v-model="user.mobile_no"
                                  placeholder="휴대전화 번호 입력"/>
                    </BFormGroup>
                    <BFormGroup class="mb-1" id="form-08" label="E-MAIL" label-cols="3" label-for="input-email" label-align="right">
                      <BFormInput id="input-email"
                                   v-model="user.email"
                                   type="text"/>
                    </BFormGroup>
                    <BFormGroup class="mb-1" id="form-41" label="권한" label-cols="3" label-for="input-user-role" label-align="right">
                      <BFormSelect id="input-user-role"
                                   v-model="user.role"
                                   :options="userRoleOptions" required/>
                    </BFormGroup>
                    <BRow>
                      <BCol sm="3"></BCol>
                      <BCol>
                        <b-button size="lg" type="submit" variant="primary"
                                  :disabled="user.user_id===null">저 장</b-button>
                      </BCol>
                      <BCol class="text-right">
                        <BButton v-if="user.user_id!==null && actionMode==='put' && isAdmin" size="lg" @click="deleteUserConfirm" variant="danger">삭제</BButton>
                        <BButton class="ml-2" size="lg" @click="isUserFormShow=false" variant="info">닫기 </BButton>
                      </BCol>
                    </BRow>
                  </BCol>
                </BRow>

              </BForm>
            </BCard>

          </BCard>

        </BCol>
      </BRow>
    </div>
  </div>
</template>

<script>
    //-------------------------------------------------------------------------------------------------
    // import moment from 'moment';
    import {
      alertModal,
      alertSuccess,
      alertError,
      apiCall,
      apiMultiPart,
      cloneVar,
      confirmModal,
      getNotice
    } from '../../../common/utils';
    import qs from 'querystring';
    import moment from "moment";


    const _user = {
      user_no   : null,
      user_id   : null,
      pwd_hash  : null,
      name      : null,
      dept      : null,
      role      : null,
      email     : null,
      phone_no  : null,
      mobile_no : null,
      reg_dt    : null,
      upd_dt    : null,
      reg_id    : null,
      upd_id    : null,
      password  : null,
    };

    const roles = {'ROLE_USER': '일반 관리자', 'ROLE_ADMIN':'수퍼 관리자' };

  //----------------------------------------------------------------------------------------------------
  export default {
    name: 'Users',
    data () {
      return {
        previewImage: null,
        imgFile: null,
        user: null,
        originPassword: '',
        newPassword: '',
        rePassword: '',
        isUserSelected: false,
        isUserFormShow: false,
        userItems: [],
        userPerPage: 20,
        userCurrPage: 1,
        userFields: [
          {key:'user_no'    , sortable:true, label:'번호'        },
          {key:'user_id'    , sortable:true, label:'사용자 아이디'   },
          {key:'name'       , sortable:true, label:'사용자명'      },
          {key:'dept'       , sortable:true, label:'소속팀'     },
          {key:'role'       , sortable:true, label:'권한'        ,formatter: v => {return (!v)? null: roles[v];  }},
          {key:'email'      , sortable:true, label:'이메일'       },
          {key:'phone_no'   , sortable:true, label:'전화번호'      },
          {key:'mobile_no'  , sortable:true, label:'휴대폰번호'     },
          {key:'reg_dt'     , sortable:true, label:'등록일'       ,formatter: v => {return (!v)? null: moment(v).format('YY/MM/DD');}},
          {key:'upd_dt'     , sortable:true, label:'수정일'       ,formatter: v => {return (!v)? null: moment(v).format('YY/MM/DD');}},
          {key:'reg_id'     , sortable:true, label:'등록자'       },
          {key:'upd_id'     , sortable:true, label:'수정자'       },
        ],
        userRoleOptions: [
          {value: 'ROLE_USER', text:'사용자'},
          {value: 'ROLE_ADMIN', text:'관리자'}
        ],



        searchOpts: [
          {value:'user_id', text:'사용자ID'},
          {value:'name', text:'사용자명'},
          {value:'phone_no', text:'전화번호'},
          {value:'email', text:'Email'},
          ],
        searchWord: null,
        searchField: 'name',
        actionMode: 'post',
        isAdmin: ($user.role==='ROLE_ADMIN')?true:false
      }
    },
    created(){

      this.user = cloneVar( _user );
      this.getUserList();

    },
    computed: {
      userRows(){
        return this.userItems.length;
      },
      validationOriginPassword(){
        return (this.originPassword!=='' && this.originPassword.length > 5);
      },
      validationPassword(){
        return (this.newPassword.length > 5 && this.newPassword===this.rePassword);
      },
      validationRePassword(){
        return this.rePassword.length > 5 && this.newPassword===this.rePassword;
      }
    },

    methods: {

      async userSubmit(evt){
        evt.preventDefault();

        // 사용자 신규등록 인 경우
        if( this.actionMode==='post'){
          if(  this.newPassword!==this.rePassword ){
            await alertError(this.$bvModal, '신규 비밀번호가 재입력 비밀번호와 일치 하지 않습니다.');
            return false;
          }

          if( this.newPassword.length < 6 || this.newPassword.length > 50){
            await alertError(this.$bvModal, '비밀번호는 6~50자로 입력해주세요');
            return false;
          }

          this.user['password'] = this.newPassword;

        }else{
          // 사용자 수정인 경우
          if( this.newPassword!=="" || this.rePassword!=="" ){

            if( this.newPassword!==this.rePassword ){
              await alertError(this.$bvModal, '신규 비밀번호가 재입력 비밀번호와 일치 하지 않습니다.');
              return false;
            }

            if( this.newPassword.length < 6 || this.newPassword.length > 50){
              await alertError(this.$bvModal, '비밀번호는 6~50자로 입력해주세요');
              return false;
            }

            this.user['new_pwd'] = this.newPassword;
          }else{
            // 패스워드 입력 하지 않은 경우
            this.user['new_pwd'] = null;

          }

          this.user['origin_pwd'] = this.originPassword;

        }

        this.saveUser();
      },


      async saveUser() {
        let r = null;

        try{
          r = await apiCall(this.actionMode, '/admin/api/user', this.user);
          if( r.code===200 ){
            await alertSuccess(this.$bvModal, '사용자 정보가 저장 되었습니다.');
                this.getUserList();
                this.isUserFormShow = false;
          }else{
            await alertError(this.$bvModal, '사용자 정보저장 실패: '+r.message);
          }

        }catch(err){
          console.log( err );
          alert(err.message);
        }
      },

      async getUserList(){
        let r = null;

        let param = {};
        if( this.searchWord ){
          param[this.searchField] = this.searchWord;
        }

        if(!this.isAdmin){
          param['user_no'] = $user.user_no;
        }

        try{
          r = await apiCall('get', `/admin/api/user?${qs.stringify(param)}`);
          this.userItems = r.result
        }catch(err){
          console.log( err );
        }
      },


      addUser(){
        this.isUserFormShow = true;
        this.$refs['userTable'].clearSelected();
        this.user = cloneVar( _user);
        console.log( "actionMode------------->", this.actionMode );
      },

      deleteUserConfirm(){
        confirmModal(this.$bvModal,
          {title:'사용자 삭제 경고', text:`${this.user.user_id} 사용자를 삭제 하시겠습니까?`,
            level:'warn'}, (ok)=>{
          if(ok) {
            this.deleteUser();
          }else{
            return;
          }
        });
      },

      async deleteUser(){
        let r = null, notice = null;

        try{
          r = await apiCall("DELETE", `/admin/api/user/${this.user.user_no}`, this.user);
          if( r.code === 200 ){
            await alertSuccess(this.$bvModal, '사용자정보가 삭제되었습니다.');

            this.getUserList();
          }else{
            await alertError(this.$bvModal, '사용자 정보 삭제 실패: ' + r.message);
          }
        }catch(err){
          console.log( err );
        }

      },

      userRowSelected(item){
        console.log("------------ userRowSelected ------------");
        if( item.length === 0 ) {
          this.user = cloneVar( _user);
          this.actionMode = "post";
          this.isUserSelected = false;
          this.isUserFormShow = false;
          this.rePassword = '';
          this.newPassword = '';
          this.originPassword = '';
        }else{
          this.user = item[0];
          this.rePassword = '';
          this.newPassword = '';
          this.originPassword = '';
          this.user.newPassword = '';
          this.user.rePassword = '';
          this.actionMode = "put";
          this.isUserSelected = true;
          console.log( "********** user----------------->", JSON.stringify(item[0]) );
        }
      },

    }
  }
</script>
